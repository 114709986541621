@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    /* body {
      -ms-overflow-style: none;
      scrollbar-width: none; 
    } */
  }
}

/* @font-face {
  font-family: WestVar;
  src: url('assets/fonts/') format('opentype');
} */

.stb-item {
  transition: font-variation-settings 700ms ease;
  font-variation-settings: "wght" 300;
}

.stb-item:hover {
  font-variation-settings: "wght" 700;
  /* letter-spacing: 1px; */
}

.cursor {
  width: 0px;
  height: 0px;
  /* background: hsl(0, 0%, 5%); */
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: hsl(0, 0%, 90%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease,
    transform 0.3s ease;
  opacity: 0;
}

.page.dark .cursor {
  /* color: hsl(0, 0%, 5%); */
  background: hsl(0, 0%, 90%);
}

.cursor.show {
  opacity: 1;
  width: 180px;
  height: 180px;
}

.cursor span {
  width: fit-content;
  display: none;
  z-index: 4;
}
